'use strict';//The Face Custom JS
/*-------------------------------------------------------------------------------
  FB SDK
-------------------------------------------------------------------------------*/(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id)){return;}js=d.createElement(s);js.id=id;js.src="//connect.facebook.net/en_US/sdk.js";fjs.parentNode.insertBefore(js,fjs);})(document,'script','facebook-jssdk');/*-------------------------------------------------------------------------------
  DEVS
-------------------------------------------------------------------------------*/console.log('\n\n         o_____________\n         | rm brooklyn |\n         |_____________|\n  (\\__/) |\n  ( \u2022\u1D25\u2022)\u3065\n  / \u3000 \u3065');/*-------------------------------------------------------------------------------
  **********DOCUMENT.READY*************
-------------------------------------------------------------------------------*/jQuery(document).ready(function($){/*-------------------------------------------------------------------------------
    Scroll
  -------------------------------------------------------------------------------*/function scrollNotificationBar(){//console.log('scrolling');
TweenLite.to($notificationBar,0.2,{opacity:0});clearTimeout($.data(this,"scrollCheck"));$.data(this,"scrollCheck",setTimeout(function(){//console.log( 'stopped' );
TweenLite.to($notificationBar,0.5,{opacity:1});},800));}function hideNotificationBar(){var $notificationText=$('#notification-bar p');if($notificationBar.hasClass('show-bar')){TweenLite.to($notificationText,0.1,{opacity:0});TweenLite.to($notificationBar,0.3,{height:0,className:'-=show-bar',backgroundColor:'black',display:'none'});}else{TweenLite.to($notificationText,0.3,{opacity:1});TweenLite.to($notificationBar,0.5,{height:'auto',className:'+=show-bar',backgroundColor:$notificationBG,display:'block'});}}function adjustHeaderHeight(){var $header=$('.header, .no-header, .no-header-single').eq(0),$notificationBarHeight=$notificationBar.height();TweenLite.to($header,0.5,{paddingTop:$notificationBarHeight});}var $notificationBar=$('#notification-bar'),$notificationBG=$notificationBar.css('background-color');if($notificationBar.length>0){//page load
adjustHeaderHeight();//events
$(window).scroll(scrollNotificationBar);$(window).resize(adjustHeaderHeight);//x out
$('#notification-close').on('click',function(){TweenLite.to($notificationBar,0.2,{height:0,onComplete:function onComplete(){$notificationBar.remove();adjustHeaderHeight();}});});}//end notification bar
/*-------------------------------------------------------------------------------
    Add FB to page
  -------------------------------------------------------------------------------*///create fb component
var fbDiv=document.createElement('div');fbDiv.id="fb-root";//add fb component to body but only if a fb-el is on the page
var body=document.body;body.insertBefore(fbDiv,body.firstChild);/*-------------------------------------------------------------------------------
    Iframe Defer
  -------------------------------------------------------------------------------*/var vidDefer=document.getElementsByTagName('iframe');for(var i=0;i<vidDefer.length;i++){if(vidDefer[i].getAttribute('data-src')){vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));}}/*-------------------------------------------------------------------------------
    Social Media
  -------------------------------------------------------------------------------*///Capitalize string for output
function getSubHead(str){str=str.toLowerCase().replace(/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g,function(letter){return letter.toUpperCase();});return str;}//get subhead
function get_social_links(socialLinks){//get data to assign
var social_data={url:theFaceSocial.url,query:theFaceSocial.query,title:theFaceSocial.title,excerpt:theFaceSocial.excerpt};var subHead;var catName=social_data.query.category_name;var postType=social_data.query.post_type;if(catName){subHead=getSubHead(catName);}else{if(postType==='song_otd'){subHead='Song Of The Day';}else{subHead=getSubHead(postType);}//if else
}//if else
//assign variables
var social_href=window.location.href;var title=social_data.title;var url=social_data.url;var description=''+social_data.excerpt;var clickWindow="window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;";// set attributes for each social media link, href share specific
for(var _i=0;_i<socialLinks.length;_i++){var network=socialLinks[_i].getAttribute('data-social');if(network==='facebook'){socialLinks[_i].setAttribute('href','https://www.'+network+'.com/sharer/sharer.php?u='+social_href);}else if(network==='twitter'){socialLinks[_i].setAttribute('href','https://'+network+'.com/intent/tweet?url='+url+'&text='+title+'&via=thefaceradiobk');}else{socialLinks[_i].setAttribute('href','https://plus.'+network+'.com/share?url='+social_href);}//if else
socialLinks[_i].setAttribute('data-href',url);socialLinks[_i].setAttribute('onclick',clickWindow);}//for loop
}//getsoicallinks
var socialLinks=document.getElementsByClassName('social-share');if(socialLinks.length>0){get_social_links(socialLinks);}//if social
/*-------------------------------------------------------------------------------
    Thumbnail Gallery
  -------------------------------------------------------------------------------*/function openPhotoSwipe(startSlide){var gallery={list:theFaceGallery.imgs};var images=gallery.list;//required by gallery
var pswpElement=document.querySelectorAll('.pswp')[0];var items=[];$.each(images,function(i,val){var caption=val.caption;if(caption===""){caption="The Face Radio";}items.push({h:val.height,w:val.width,src:val.url,title:caption//msrc: val.sizes.thumbnail
});});// define options
var options={index:startSlide,// start at first slide
bgOpacity:0.7,showHideOpacity:true,getThumbBoundsFn:false};var swipeGallery=new PhotoSwipe(pswpElement,PhotoSwipeUI_Default,items,options);swipeGallery.init();}var $thumbnails=$(".gallery-image a");$thumbnails.on("click",function(){var imgChoice=parseInt($(this).attr('data-num'));openPhotoSwipe(imgChoice);});/*-------------------------------------------------------------------------------
    Social Animations
  -------------------------------------------------------------------------------*/function social_swing(){var $this=$(this);var tl=new TimelineLite();var $social=$this.find('i');tl.to($social,0.3,{rotation:15,transformOrigin:"50% 0%"}).to($social,0.3,{rotation:-15,transformOrigin:"50% 0"}).to($social,0.3,{rotation:15,transformOrigin:"50% 0"}).to($social,0.5,{rotation:0,transformOrigin:"50% 0"});}var $socialShare=$('.social-share');$socialShare.hover(social_swing);var $loader=$('.loading-spinner');var spinner=new TweenMax.to($loader,0.8,{rotation:"360",ease:Linear.easeNone,repeat:-1});/*-------------------------------------------------------------------------------
    Nav and Page Opacity Animations
  -------------------------------------------------------------------------------*///Nav Variables
var $slideEl=$('.slide');var $opacity=$('#opacity');var bgPsuedoRule=CSSRulePlugin.getRule('.opacity-container:after');var navToFade=document.getElementsByClassName('nav-wrap');//GSAP set variables - page loads this way
TweenLite.set($slideEl,{visibility:'visible'});TweenLite.set(navToFade[0],{opacity:1});var menuToggle=new TimelineMax({paused:true,reversed:true});menuToggle.to('#nav-icon .one',0.2,{y:'-9px',transformOrigin:'50% 50%'},'burg').to('#nav-icon .three',0.2,{y:'9px',transformOrigin:'50% 50%'},'burg').to('#nav-icon .two',0.2,{scale:0.1,transformOrigin:'50% 50%'},'burg').add('rotate').to('#nav-icon .one',0.2,{y:'15'},'rotate').to('#nav-icon .three',0.2,{y:'-10'},'rotate').to('#nav-icon .one',0.2,{rotationZ:45,transformOrigin:'50% 50%'},'rotate').to('#nav-icon .three',0.2,{rotationZ:-45,transformOrigin:'50% 50%'},'rotate');function slide_that_nav(){menuToggle.reversed()?menuToggle.restart():menuToggle.reverse();var $this=$(this);var $targetEl=$('.nav-slide');$('#nav-icon').toggleClass('open');//target the slide connected to click event
if(!$this.hasClass('closed')){//remove the body opacity if Nav
TweenLite.to($opacity,0.8,{className:'-=opacity-container',backgroundColor:'rgba(0,0,0,0)'});//animate the slide up
TweenLite.to($targetEl,1,{height:0,ease:Power2.easeOut});TweenLite.to(bgPsuedoRule,0.5,{cssRule:{backgroundColor:'transparent',opacity:'0'}});$this.addClass('closed');}else{//add the body 0pacity if Nav
TweenLite.to($opacity,2,{className:'+=opacity-container',backgroundColor:'rgba(0,0,0,1)',ease:Power2.easeOut});//animate the slide down
TweenLite.set($targetEl,{height:'auto'});TweenLite.from($targetEl,0.5,{height:0,ease:Power2.easeOut});TweenLite.to(bgPsuedoRule,1,{cssRule:{backgroundColor:'#000',opacity:0.7}});$this.removeClass('closed');}}var $buTog=$('.nav-toggle');$buTog.on('click',slide_that_nav);$buTog.on('click',hideNotificationBar);/*-------------------------------------------------------------------------------
    Audio Player --   //thank you Nick Colley http://codepen.io/nickcolley/pen/rvjeu
  -------------------------------------------------------------------------------*/function convertTime(time){var hours,minutes,seconds,timeRemaining,hours=parseInt(time/3600),minutes=parseInt((time-hours*3600)/60),seconds=Math.floor(time-(hours*3600+minutes*60)),timeRemaining=(hours<10?"0"+hours:hours)+":"+(minutes<10?"0"+minutes:minutes)+":"+(seconds<10?"0"+seconds:seconds);return timeRemaining;}function playAudio(){var $this=$(this);var playButton='fa fa-play-circle fa-2x';var pauseButton='fa fa-pause-circle fa-2x';var pauseButtons=$('.player-pause');var $el=$this.find('span');var $audioWrap=$this.closest('div.audio-wrapper');var $progressDiv=$audioWrap.find('.audio-progress');var $progressBar=$audioWrap.find('.progress-bar');var $player=$audioWrap.find('audio').get(0);//let liveURL = 'http://188.165.240.90:8904/stream';
// let liveURL = 'https://securestreams5.autopo.st:1877/stream';
var liveURL='https://thefaceradio.out.airtime.pro/thefaceradio_a';//clicking on play arrow
if($player.paused){//pause anything else that may be playing
if(pauseButtons.length>0){var audio=$('audio');audio.each(function(x,el){if(el.paused===false){if($(el).is('.live')){endLiveStream(el);}else{//pause the audio
el.pause();}};});pauseButtons.each(function(x,el){el.classList.remove('player-pause');$(el).find('span').get(0).classList.remove('fa-pause-circle');$(el).find('span').get(0).classList.add('fa-play-circle');});}//carry on
$el.attr('class',pauseButton);this.classList.add('player-pause');//if Live player re-add stream to src
if($($player).is('.live')){$($player).attr("src",liveURL);//play on page change
sessionStorage.setItem('autoplay',true);}//end if live
$player.play();}else{//clicking on pause arrow
$el.attr('class',playButton);this.classList.remove('player-pause');//stop stream downloading for LIVE player
if($($player).is('.live')){endLiveStream($player);sessionStorage.setItem('autoplay',false);}else{$player.pause();}//end live if/else
}//Zero the progress bar
var pOffset=$progressBar.offset();var scrub;//Event listener - change width of progress bar by timeupdate
$player.addEventListener('timeupdate',function(){//Update progress bar
$progressBar.css('width',$player.currentTime/$player.duration*100+'%');//Set the Time
var $timeCountDown=$this.parent().find('.listen-time').get(0),$timeCountUp=$this.parent().find('.remaining-time').get(0),showDuration=parseInt($player.duration),currentTime=parseInt($player.currentTime),totalNumberOfSeconds=showDuration-currentTime;//set the html for showTime if it exists (liveaudio bar does not show)
if($timeCountDown){$timeCountDown.innerHTML=convertTime(totalNumberOfSeconds);}//only show if single version audio time counter
if($timeCountUp){$timeCountUp.innerHTML=convertTime(currentTime);}},false);$player.addEventListener("ended",function(){$player.currentTime=0;$el.attr('class',playButton);$audioClick[0].classList.remove('player-pause');},false);//Get position of mouse for scrubbing
$progressDiv.mousemove(function(e){scrub=e.pageX-pOffset.left;});//update the css for the duration and song position of click location
$progressDiv.click(function(){$progressBar.css('width',scrub+'px');var totalWidth=$progressDiv.css('width');totalWidth=parseInt(totalWidth,10);var seek=$player.duration*(scrub/totalWidth);$player.currentTime=seek;});}//end play audio
var $audioClick=$('.arrow');$audioClick.on('click',playAudio);//turn off live stream
function endLiveStream(audio){$(audio).attr("src","");audio.pause();setTimeout(function(){// This stops the downloading stream
audio.load();});//end timeout
}/*-------------------------------------------------------------------------------
    Show What's Playing, check for updates
  -------------------------------------------------------------------------------*/function millisToMinutesAndSeconds(varName,millis){var minutes=Math.floor(millis/60000);var seconds=(millis%60000/1000).toFixed(0);console.log(varName,minutes+":"+(seconds<10?'0':'')+seconds);}// timer function to check for update
function runOnInterval(interval_in_ms,function_to_run){var only_run_once=arguments.length>2&&arguments[2]!==undefined?arguments[2]:false;setTimeout(function(){function_to_run();if(!only_run_once)function_to_run();},interval_in_ms);}var decodeHTML=function decodeHTML(html){var txtArea=document.createElement("textarea");var formatHTML=html.includes("with")?html.split("with"):html;if(typeof formatHTML!=='string'){formatHTML=formatHTML[0]+' <span class="hide-for-small show-for-medium"> with '+formatHTML[1]+' </span>';}txtArea.innerHTML=formatHTML;return txtArea.value;};// var endAndStartTimer = (function (f, y) {
//   var timer; // variable persisted here
//   console.log('timer outter', timer)
//   return function () {
//     window.clearTimeout(timer);
//     //var millisecBeforeRedirect = 10000; 
//     timer = window.setTimeout(function(){f},y); 
//     console.log('timer recursive', timer)
//   };
// })();
// endAndStartTimer(z, 1000);
//var timer1 = setTimeout(function() { removeStatusIndicator(); }, statusTimeout)
// clearTimeout(timer1)
var prepareForFreeze=function prepareForFreeze(){// Close any open IndexedDB connections.
// Release any web locks.
// Stop timers or polling.
console.log('preparing to freeze');};var reInitializeApp=function reInitializeApp(){// Restore IndexedDB connections.
// Re-acquire any needed web locks.
// Restart timers or polling.
console.log('freeze ended');};// document.addEventListener('freeze', prepareForFreeze);
// document.addEventListener('resume', reInitializeApp);
//TODO 
// document.addEventListener("visibilitychange", function() {
//   if (document.visibilityState === 'visible') {
//     console.log('back on page')
//     // recall function
//     // renderData();
//   } else {
//     console.log('left page')
//     // clear timeout
//   }
// });
// Update Text for Now Playing
var updateNowPlaying=function updateNowPlaying(apiData){var whatsOn=document.getElementById('whatsOn');var whatsNext=document.getElementById('whatsNext');var nowPlaying=document.getElementById('nowPlaying');var upNext=document.getElementById('upNext');var currentPlayingText=apiData.currentShow[0].name;var upNextText=apiData.nextShow[0].name;var startTime=dayjs(apiData.currentShow[0].starts).format('HH:mm');var endTime=dayjs(apiData.currentShow[0].ends).format('HH:mm');var nextStartTime=dayjs(apiData.nextShow[0].starts).format('HH:mm');var nextEndTime=dayjs(apiData.nextShow[0].ends).format('HH:mm');if(whatsOn){nowPlaying.textContent='Now Playing: '+startTime+' - '+endTime+' ';whatsOn.innerHTML=''+decodeHTML(currentPlayingText);}if(whatsNext){upNext.textContent='Up Next: '+nextStartTime+' - '+nextEndTime+' ';whatsNext.innerHTML=''+decodeHTML(upNextText);}};// hit up the API
async function getFaceData(){var url='https://thefaceradio.airtime.pro/api/live-info';try{var res=await fetch(url);return await res.json();}catch(error){console.log(error);}}// Pass that Data
async function renderData(){var faceData=await getFaceData();// console.log('renderData is running at:', new Date())
// update page text
updateNowPlaying(faceData);// get show's end time
var showEndTime=dayjs(faceData.currentShow[0].ends);var current=dayjs();var diff=showEndTime.diff(current);// set function to run again
runOnInterval(Math.abs(diff),renderData,true);//millisToMinutesAndSeconds('time', Math.abs(diff))
return faceData;}renderData();// END FILE loading all functions doc.ready
});