'use strict';//Courtesy of Bill Erickson! http://www.billerickson.net/infinite-scroll-in-wordpress/
/*-------------------------------------------------------------------------------
  Click Animations
-------------------------------------------------------------------------------*/// function animateNewDivs(newDivs) {
//     TweenLite.set(newDivs, { opacity: 0 });
//     //move in, remove class so not repeat on subsequent loads
//     TweenLite.from(newDivs, 1.2, { y: 500 });
//     TweenLite.to(newDivs, 1.5, { opacity: 1 });
//     newDivs.removeClass('animate-scroll heighten');
// }
// function fadeOutLoadMore(footer){
//    TweenLite.to(footer, 0.8, {y: 800, ease: Power1.easeInOut});
//    TweenLite.to(footer, 0.5, {y: 0, delay: 1});
// }
jQuery(function($){//Check Limit with this nonce
var data_check={action:'theface_ajax_check_limit',nonce:theFaceCheckLimits.nonce,maxpages:theFaceCheckLimits.maxpages,posttype:theFaceCheckLimits.posttype};var max_check=data_check.maxpages;var max_post=data_check.posttype;if(max_check<2){//Remove from Taxonomy searches
return;}else if(//Remove from pages and onair-schedule
max_post==='onair_schedule'||max_post==='page'){return;}else{//Let's do this!
//BOTH Scroll & Click Vars - Do not comment out
var $ajaxAnchor=$('#content');var load_more_watcher=$('.load-more');var page=2;var loading=false;var maxpages;var posttype;/*-------------------------------------------------------------------------------
      CLICK LOAD MORE FUNCTION
    -------------------------------------------------------------------------------*/// var $loadButton = $('<button class="load-more first" />');
// $loadButton.text('LOAD MORE')''
// var animateDownDivs = $('.animate-down');
// animateDownDivs.prepend($loadButton);
// var button = $('.load-more');
// button.on('click', function() {
//     //Gather and append with this nonce
//     fadeOutLoadMore(animateDownDivs);
//     var data = {
//         action: 'theface_ajax_load_more',
//         nonce: theFaceLoadMore.nonce,
//         page: page,
//         query: theFaceLoadMore.query,
//         maxpages: theFaceLoadMore.maxpages,
//         posttype: theFaceLoadMore.posttype,
//         postcount: theFaceLoadMore.postcount
//     };
//     maxpages = data.maxpages;
//     posttype = data.posttype;
//     console.log('PAGE', page);
//     console.log('MAX', maxpages);
//     console.log('posttype', data.posttype);
//     console.log('postcount', data.postcount);
//     console.log('data', data);
//     console.log('CLICKED');
//     $.post(theFaceLoadMore.url, data, function(res) {
//         if (res.success) {
//             //append data to content div
//             var $messyData = $.parseHTML(res.data);
//             var $tidyData = $('<div class="pull-more animate-scroll" / >');
//             $tidyData.html($messyData);
//             $($tidyData).appendTo($ajaxAnchor);
//             var $lastDiv = $('.content-block:last-child');
//             var $alter_last = $lastDiv.last();
//             if($lastDiv.length > 1){
//                 $lastDiv.css("margin-bottom", "20px");
//                 $alter_last.css('margin-bottom', '0');
//             }
//             //set up animations for that new data
//             var newDivs = $('.animate-scroll');
//             animateNewDivs(newDivs);
//             //add the button
//             animateDownDivs.prepend(load_more_watcher);
//             //Remove Button when pages are maxed
//             if (page === maxpages || maxpages === 1) {
//                 //stop scroll
//                 console.log('removed load_more_watcher');
//                 button.remove();
//             } else {
//                 //keep watching
//                 page = page + 1;
//             }
//         } else {
//             console.log('blerk! no data');
//         }
//     }).done(function() {
//         var $equalizer_el = $('.equalize-more');
//         console.log($equalizer_el.length);
//         //initiate equalizer on data after page load
//         if($equalizer_el.length > 0){
//            $equalizer_el.foundation();
//         //update equalizer only if element needs it;
//            $(window).on('resize', function(e) {
//                // Adjust matching heights on screen resize for new data
//                Foundation.reInit('equalizer');
//            })
//         }
//     }).fail(function(xhr, textStatus, e) {
//         console.log("failed:", xhr.responseText);
//     });
// }) //end click function
/*-------------------------------------------------------------------------------
      SCROLL FUNCTION
    -------------------------------------------------------------------------------*/var $loadSpan=$('<span class="scroll-more" />');$ajaxAnchor.append($loadSpan);var $button=$('.scroll-more');var loadScroll={allow:true,reallow:function reallow(){loadScroll.allow=true;},delay:250//(milliseconds) adjust to the highest acceptable value
};var $searcher=$('.searcher');var postList=[];if($searcher){$searcher.each(function(){postList.push($(this).data("id"));});}var initialHeight=$(window).innerHeight();//get the buttons
var $faceButtons=$('.face-button-set');var getInfo=function getInfo(){//set width and height and margins for sticky buttons class
var contentWidth=$ajaxAnchor.width();var contentPadding=$ajaxAnchor.css('padding-left');//convert to numbers and add to width
contentPadding=parseInt(contentPadding.slice(0,-2))*2;contentWidth+=contentPadding;var contentContain=$('#main');var contentMargin=contentContain.css('margin-left');var bodyWidth=contentContain.css('max-width');//access locations to trigger ajax and sticky
var pageBottom=$('.animate-down').height()+$('footer').height();var windowLocal=Math.floor($(window).scrollTop()+$(window).height());var footerLocal=Math.ceil($(document).height()-pageBottom+100);var stickBottom=windowLocal>=footerLocal-50;var stickTop=windowLocal<=initialHeight+100;if(Foundation.MediaQuery.current==='small'||stickBottom||stickTop){$faceButtons.removeClass('button-flow').css('max-width',bodyWidth);}else{$faceButtons.addClass('button-flow');$faceButtons.css({'max-width':contentWidth,'margin-left':contentMargin});}};//end getInfo
$(window).resize(getInfo);$(window).scroll(function(){$searcher=$('.searcher');if($searcher){var id=void 0;$searcher.each(function(){id=$(this).data('id');//only push if it isn't already here
if($.inArray(id,postList)===-1){postList.push(id);}});}//set width and height and margins for sticky buttons class
var offset=$button.offset().top-$(window).scrollTop();//Handles Ajax Scroll
if(!loading&&loadScroll.allow){loadScroll.allow=false;//throttle the scroll handler
setTimeout(loadScroll.reallow,loadScroll.delay);//determine when to call data
if(3000>offset){var data={action:'theface_ajax_load_more',nonce:theFaceLoadMore.nonce,page:page,query:theFaceLoadMore.query,maxpages:theFaceLoadMore.maxpages,posttype:theFaceLoadMore.posttype,postcount:theFaceLoadMore.postcount,ajax_ids:postList};maxpages=data.maxpages;posttype=data.posttype;loading=true;$.post(theFaceLoadMore.url,data,function(res){//console.log('res', res);
if(res.success&&res.data!==""){var $messyData=$.parseHTML(res.data);var $tidyData=$('<div class="pull-more animate-scroll" / >');$tidyData.html($messyData);$tidyData.appendTo($ajaxAnchor);//append the data;
$ajaxAnchor.append(load_more_watcher);//Last Child Spacing Issues
var $lastDiv=$('.content-block:last-child');var $alter_last=$lastDiv.last();if($lastDiv.length>1){$lastDiv.css("margin-bottom","20px");$alter_last.css('margin-bottom','0');}if(page>=maxpages||maxpages===1){//stop scroll
loadScroll.allow=false;}else{//keep watching
page=page+1;loading=false;}}}).fail(function(xhr){//console.log('ARgh, failed:', xhr.responseText);
}).done(function(){var $equalizer_el=$('.equalize-more');//initiate equalizer on data after page load
if($equalizer_el.length>0){$equalizer_el.foundation();//update equalizer only if element needs it;
$(window).on('resize',function(){// Adjust matching heights on screen resize for new data
Foundation.reInit('equalizer');});$('.large-up-3').removeClass('equalize-more');}});//end if offset
}}//if allowscrolling
//remove buttons on small, or if at top/bottom of page
getInfo();});//scroll function
}//end else for Let's Do This
});//end file